import i18n from 'i18next'
import { initReactI18next, useTranslation as useOriginalTranslation } from 'react-i18next'
import enJson from 'i18n/en.json'
import fiJson from 'i18n/fi.json'
import svJson from 'i18n/sv.json'
import nlJson from 'i18n/nl.json'

const translationNamespace = 'translation'

const resources = {
  en: {
    [translationNamespace]: enJson
  },
  fi: {
    [translationNamespace]: fiJson
  },
  sv: {
    [translationNamespace]: svJson
  },
  nl: {
    [translationNamespace]: nlJson
  }
}

export const languageLabels: Record<string, string> = {
  fi: 'Suomi',
  en: 'English',
  sv: 'Svenska',
  nl: 'Neterlands'
}

export const supportedLanguages = Object.keys(resources)

void i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    defaultNS: translationNamespace,
    interpolation: {
      escapeValue: false // React already safe from xss
    },
    keySeparator: false, // Required for flat JSON translation files when the key syntax happens to include a "." to prevent i18next from ******* up the translation result
    saveMissing: true, // Required for onMissingKey to trigger
    fallbackLng: false // Required for the onMissingKey lng parameter to indicate the initial language of the queried key instead of the fallback language
  })

export const useTranslation = () => {
  const { t, ...rest } = useOriginalTranslation()

  /**
   * @param {(string)} key
   * @param {(string)} defaultValue
   * @returns {(string|undefined)} The translation or undefined if translation not found (never the translation key.)
   * @description tOptional will not trigger the missing key event in case the requested translation does not exist. Results may vary depending on the i18n init options.
   */
  const tOptional = (key: string, defaultValue?: string) => {
    const translationExists = i18n.exists(key)

    if (translationExists) {
      return t(key)
    } else {
      return defaultValue ?? undefined
    }
  }

  return { t, tOptional, ...rest }
}

export default i18n
