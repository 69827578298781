import { createContext, type ReactNode, useState, useContext, useRef } from 'react'

export type NotificationContextType =
| 'global'
| 'home'
| 'courses'
| 'provisioning'
| 'data-sharing'
| 'profile'

// We don't call it just Notification because it would conflict with the native JS Notification API type
export interface PortalNotification {
  id: number
  context?: NotificationContextType
  label?: string
  message: string
  messageParameters?: Record<string, string | undefined>
  actions?: ReactNode
  externalId?: string | number
}

interface NewNotification {
  context?: NotificationContextType
  message: string
  messageParameters?: Record<string, string | undefined>
  label?: string
  actions?: (id: number) => ReactNode
  externalId?: string | number
}

interface DismissnotificationWithId {
  id: number
  externalId?: never
}

interface DismissNotificationWithExternalId {
  id?: never
  externalId: string | number
}

type DismissNotification = DismissnotificationWithId | DismissNotificationWithExternalId

interface NotificationContextValue {
  notifications: PortalNotification[]
  createNotification: (notification: NewNotification) => void
  dismissNotification: ({ id, externalId }: DismissNotification) => void
}

const NotificationContext = createContext<NotificationContextValue>({
  notifications: [],
  createNotification: () => {},
  dismissNotification: () => {}
})

export const NotificationProvider = ({ children }: { children?: ReactNode }) => {
  const [notifications, setNotifications] = useState<PortalNotification[]>([])
  const notificationId = useRef(0)

  const createNotification = ({ context = 'global', label, message, messageParameters, actions, externalId }: NewNotification) => {
    const id = notificationId.current++
    const actionsProp = actions ? actions(id) : undefined

    const newNotification: PortalNotification = {
      id,
      context,
      label,
      message,
      messageParameters,
      actions: actionsProp,
      externalId
    }

    setNotifications(prevNotifications => [...prevNotifications, newNotification])
  }

  const dismissNotification = ({ id, externalId }: DismissNotification) => {
    if (externalId) {
      setNotifications(prevNotifications => prevNotifications.filter(notification => notification.externalId !== externalId))
      return
    }

    setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id))
  }

  return (
    <NotificationContext.Provider value={{ notifications, createNotification, dismissNotification }}>
      {children}
    </NotificationContext.Provider>
  )
}

const filterByContext = (notifications: PortalNotification[], context: NotificationContextType) => {
  return notifications.filter((notification) => notification.context === context || notification.context === 'global')
}

export const useNotifications = (context?: NotificationContextType) => {
  const { notifications, createNotification, dismissNotification } = useContext(NotificationContext)

  return {
    notifications: context ? filterByContext(notifications, context) : notifications,
    createNotification,
    dismissNotification,
    filterByContext
  }
}
