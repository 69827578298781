import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport
} from 'components/ui/toast'
import { useToast } from 'components/ui/use-toast'

export const Toaster = () => {
  const { toasts } = useToast()
  const tenSeconds = 10000

  return (
    <ToastProvider duration={tenSeconds}>
      {toasts.map(({ id, title, description, action, ...props }) => {
        return (
          <Toast key={id} {...props}>
            <div className='grid gap-1'>
              {title && (
                <ToastTitle>
                  {title}
                </ToastTitle>
              )}

              {description && (
                <ToastDescription>
                  {description}
                </ToastDescription>
              )}
            </div>

            {action}
            <ToastClose />
          </Toast>
        )
      })}

      <ToastViewport />
    </ToastProvider>
  )
}
