import { Button, type ButtonProps } from './ui/button'
import { Loader2 } from 'lucide-react'
import { cn } from 'lib/utils'

export default function LoadingButton ({
  isLoading,
  children,
  className,
  ...props
}: {
  isLoading?: boolean
  children?: React.ReactNode
  className?: string
} & ButtonProps) {
  return (
    <Button
      type='submit'
      // NOTE: If manually disabling a LoadingButton, it will override this setting and you probably want to add || isLoading to the condition. Otherwise it will be clickable when loading.
      disabled={isLoading}
      className={cn('relative', className)}
      {...props}
    >
      {isLoading && (
        <Loader2 className='absolute left-1/2 top-1/2 -mt-2.5 -ml-2.5 h-5 w-5 animate-spin' />
      )}

      <div className={isLoading ? 'text-transparent' : ''}>
        {children}
      </div>
    </Button>
  )
}
