import { cn } from 'lib/utils'
import { type ReactNode } from 'react'

interface BoxProps {
  children?: ReactNode
  className?: string
  fullWidthOnMobile?: boolean
  label?: string | null
}

function Box ({ children, className, fullWidthOnMobile, label }: BoxProps) {
  return (
    <div className={cn('border bg-white p-4 md:py-8 md:px-8', fullWidthOnMobile ? '-mx-4 sm:mx-0 rounded-none sm:rounded-xl' : 'rounded-xl', className)}>
      {label && (
        <div className='text-[10px] text-[#999] uppercase'>
          {label}
        </div>
      )}

      {children}
    </div>
  )
}

export { Box }
