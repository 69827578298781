import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { Section } from 'components/ui/section'
import { Button } from 'components/ui/button'
import { useAppContext } from 'contexts/AppContext'

function PublicHomepage () {
  const { t, i18n } = useTranslation()
  const { api } = useAppContext()

  const signUp = async () => {
    await api.signUpUniversal(i18n.language)
  }

  return (
    <Section>
      <Header
        title={t('homepagePublic.title')}
        subtitles={[
          t('homepagePublic.subtitle', ''),
          t('homepagePublic.subtitle2', ''),
          t('homepagePublic.subtitle3', ''),
          t('homepagePublic.subtitle4', ''),
          t('homepagePublic.subtitle5', ''),
          t('homepagePublic.subtitle6', '')
        ]}
      />

      {process.env.REACT_APP_ENV === 'local' && (
        <div className='sm:hidden'>
          <Button onClick={signUp}>
            {t('buttons.register')}
          </Button>
        </div>
      )}
    </Section>
  )
}

export default PublicHomepage
