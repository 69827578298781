import { cn } from 'lib/utils'
import { type ReactNode, type ComponentType } from 'react'
import { NavLink, type NavLinkProps } from 'react-router-dom'

const BottomNavigation = ({ children }: { children: ReactNode }) => {
  return (
    <nav className='fixed bottom-0 left-0 z-50 w-full h-16 bg-white shadow-[0px_-3px_4px_0px_rgba(0,0,0,0.15)] text-center'>
      <div className='flex justify-between gap-2 px-3 h-full max-w-lg mx-auto'>
        {children}
      </div>
    </nav>
  )
}

const BottomNavigationItem = ({ children, to, ...props }: NavLinkProps) => {
  return (
    <NavLink to={to} className={getNavigationItemStyles} {...props}>
      {children}
    </NavLink>
  )
}

// NavLink className prop will call any function provided to it with the isActive parameter
const getNavigationItemStyles = ({ isActive }: { isActive: boolean }) => {
  return cn(
    'inline-flex flex-col items-center justify-center group border-transparent [&>span]:border-b-2 [&>span]:border-transparent [&>span]:pb-1 pt-2',
    isActive ? 'text-primary [&>span]:border-primary' : ''
  )
}

const BottomNavigationItemLabel = ({ Icon, children }: { Icon: ComponentType<{ className?: string }>, children?: ReactNode }) => (
  <>
    <Icon className='group-hover:text-primary' />

    <span className='text-xs group-hover:text-primary sm:text-sm'>
      {children}
    </span>
  </>
)

export {
  BottomNavigationItemLabel,
  BottomNavigationItem,
  BottomNavigation
}
