import { NavLink } from 'react-router-dom'
import { LogoutMenu } from './LogoutMenu'
import { type ProfilePermissions, useCurrentUserContext } from '../../contexts/CurrentUserContext'
import { cn } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LogoSVG } from '../../assets/images/aaltoee-logo.svg'

import {
  BottomNavigation,
  BottomNavigationItem,
  BottomNavigationItemLabel
} from 'components/ui/bottom-navigation'

import { HomeIcon, FileLock2, FileOutput, User2Icon, GraduationCapIcon } from 'lucide-react'

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle
} from 'components/ui/navigation-menu'

import { LanguageSelector } from 'components/LanguageSelector'
import { Button } from 'components/ui/button'
import { useAppContext } from 'contexts/AppContext'
import useRegisterFlow from 'pages/register/useRegisterFlow'

function NaviBar () {
  const { t, i18n } = useTranslation()
  const { authenticated, permissions, profileLoading } = useCurrentUserContext()
  const { state } = useRegisterFlow()
  const isOnLoginFlow = state != null
  const { api } = useAppContext()

  const login = async () => {
    await api.loginUniversal(i18n.language)
  }

  const signUp = async () => {
    await api.signUpUniversal(i18n.language)
  }

  return (
    <header className='top-container border-b border-[#ccc] py-4 bg-white'>
      <div className='container flex justify-between items-center flex-wrap gap-4 min-h-[40px]'>
        <div className='flex items-center'>
          <NavLink end to='/' title={t('navigation.Homepage')}>
            <LogoSVG className='h-[24px] md:h-[32px]' />
          </NavLink>
        </div>

        {authenticated && (
          <>
            <div className='hidden md:block'>
              <Navigation permissions={permissions} />
            </div>

            <div>
              <LogoutMenu />
            </div>
          </>
        )}

        {!authenticated && !profileLoading && (
          <div className='flex flex-row gap-2'>
            <LanguageSelector />

            {!isOnLoginFlow &&
              <>
                <Button onClick={login}>
                  {t('buttons.loginUniversal')}
                </Button>

                {process.env.REACT_APP_ENV === 'local' && (
                  <Button className='hidden sm:block' onClick={signUp}>
                    {t('buttons.register')}
                  </Button>
                )}
              </>}
          </div>
        )}
      </div>

      {authenticated && (
        <div className='md:hidden'>
          <BottomNavigation>
            <BottomNavigationItem to='/' end>
              <BottomNavigationItemLabel Icon={HomeIcon}>
                {t('navigation.Homepage')}
              </BottomNavigationItemLabel>
            </BottomNavigationItem>

            {permissions.isParticipant && (
              <BottomNavigationItem to='/courses'>
                <BottomNavigationItemLabel Icon={GraduationCapIcon}>
                  {t('navigation.Courses')}
                </BottomNavigationItemLabel>
              </BottomNavigationItem>
            )}

            {permissions.isDataSharingSupervisor && (
              <BottomNavigationItem to='/data-sharing'>
                <BottomNavigationItemLabel Icon={FileLock2}>
                  {t('navigation.Company')}
                </BottomNavigationItemLabel>
              </BottomNavigationItem>
            )}

            {permissions.isProvisioningSupervisor && (
              <BottomNavigationItem to='/provisioning'>
                <BottomNavigationItemLabel Icon={FileOutput}>
                  {t('navigation.Provisioning')}
                </BottomNavigationItemLabel>
              </BottomNavigationItem>
            )}

            <BottomNavigationItem to='/user'>
              <BottomNavigationItemLabel Icon={User2Icon}>
                {t('navigation.Profile')}
              </BottomNavigationItemLabel>
            </BottomNavigationItem>
          </BottomNavigation>
        </div>
      )}
    </header>
  )
}

export function Navigation ({ permissions }: { permissions: ProfilePermissions }) {
  const { t } = useTranslation()

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavItem path='/' title={t('navigation.Homepage')} />
        {permissions.isParticipant && <NavItem path='/courses' title={t('navigation.Courses')} />}
        {permissions.isDataSharingSupervisor && <NavItem path='/data-sharing' title={t('navigation.Company')} />}
        {permissions.isProvisioningSupervisor && <NavItem path='/provisioning' title={t('navigation.Provisioning')} />}
        <NavItem path='/user' title={t('navigation.Profile')} />
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const NavItem = ({ path, title }: { path: string, title: string }) => (
  <NavigationMenuItem>
    <NavLink
      to={path}
      className={({ isActive }) => cn(navigationMenuTriggerStyle(), isActive ? 'border-b-2 text-primary' : '')}
    >
      {title}
    </NavLink>
  </NavigationMenuItem>
)

export default NaviBar
