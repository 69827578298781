import { useToast } from 'components/ui/use-toast'
import { type ApiError } from 'ext/IamApi'
import i18next from 'i18next'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export function useApiError () {
  const { t } = useTranslation()
  const { toast } = useToast()

  const getErrorMessage = useCallback((error: ApiError | null | undefined) => {
    if (!error) {
      return ''
    }

    if (error.errorCode) {
      const errorTranslationKey = `errors.${error.errorCode}`
      const translationExists = i18next.exists(errorTranslationKey)

      if (translationExists) {
        // We need to know the error.data object property names in the translation for the i18next interpolation to work.
        // E.g. an error data object { agreementCode: 124 } will require a translation string with '{{agreementCode}}' in it: 'Agreement {{agreementCode}} not found'
        const translation = t(errorTranslationKey, error.data)

        return typeof translation === 'string'
          ? translation
          : '' // For some reason the t function will also expect to return a "TFunctionDetailedResult" when the error.data parameter is added
      }

      return ''
    }

    return error.message ?? t('errors.UNKNOWN_ERROR')
  }, [t])

  const showErrorToast = useCallback((error: ApiError | null | undefined) => {
    const errorMessage = getErrorMessage(error)
    if (errorMessage) {
      toast({
        title: errorMessage,
        description: getSession(error)
      })
    } else {
      toast({
        variant: 'destructive',
        title: t('generic.networkError.title'),
        description: `${t('generic.networkError.default')}${getSession(error)}`
      })
    }
  }, [getErrorMessage, t, toast])

  const getSession = (error: any) => {
    if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev') {
      return `\n\n${error?.session}`
    }
  }

  return { getErrorMessage, showErrorToast }
}
