import { createContext, useState, useContext } from 'react'
import { HideProfile } from '../ext/HideProfiler'

interface DataContextType {
  user: HideProfile
  setUser: any
  editing: boolean
  setEditing: any
}

const DataContext = createContext<DataContextType | null>(null)

export const DataProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState(new HideProfile())
  const [editing, setEditing] = useState(false)

  // includes blocked_for and roles via inflater
  return (
    <DataContext.Provider value={{ user, setUser, editing, setEditing }}>
      {children}
    </DataContext.Provider>
  )
}

export const useDataContext = () => useContext(DataContext) as DataContextType
