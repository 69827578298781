import { cn } from 'lib/utils'
import { type VariantProps, cva } from 'class-variance-authority'

const headingVariants = cva('text-heading font-bold', {
  variants: {
    size: {
      md: 'text-lg md:text-xl',
      lg: 'text-xl md:text-2xl',
      xl: 'text-2xl md:text-3xl'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4'

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement>, VariantProps<typeof headingVariants> {}

const Heading = ({
  className,
  children,
  size,
  as = 'h2',
  ...props
}: HeadingProps & { as?: HeadingTypes }) => {
  const HeadingComponent = as
  return (
    <HeadingComponent
      className={cn(headingVariants({ size }), className)}
      {...props}
    >
      {children}
    </HeadingComponent>
  )
}

export { Heading }
