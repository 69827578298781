/**
 * The notification engine is responsible for creating and managing notifications for the user.
 * Notifications can be created with a message, label, context, externalId, and actions.
 * The message is the main content of the notification.
 * The label is a short description of the notification.
 * The context is the area of the application where the notification should be displayed.
 * The externalId is an optional identifier for the notification.
 * The actions are optional buttons or links that the user can click to perform an action related to the notification.
 * Notifications can be created and managed using the useNotifications hook from the NotificationContext.
 * Notifications can be displayed in various areas of the application, such as a global notification bar or a specific component.
 * The useAuthenticatedUserNotifications hook is an example of how to create notifications for the authenticated user on app load based on profile conditions.
 */
import { useCurrentUserContext } from 'contexts/CurrentUserContext'
import { type NotificationContextType, useNotifications } from 'contexts/NotificationContext'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/button'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from 'contexts/AppContext'
import { useQuery, useMutation } from '@tanstack/react-query'
import { type ApiNotificationAction, type ApiNotification } from 'ext/IamApi'
import { startsWith } from 'lodash'
import LoadingButton from 'components/LoadingButton'

const ActionButton = ({ notificationId, action, addCloseButton }: { notificationId: string, action?: ApiNotificationAction, addCloseButton?: boolean }) => {
  const { dismissNotification } = useNotifications()
  const { api } = useAppContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isLoading: isDismissing, mutateAsync: handleDismissNotification } = useMutation({
    mutationFn: async (notificationId: string) => {
      await api.dismissNotification(notificationId)
      return notificationId
    },
    onSuccess: async (notificationId: string) => {
      dismissNotification({ externalId: notificationId })
    }
  })

  if (!action) {
    if (addCloseButton) {
      return (
        <Button
          onClick={async () => {
            await handleDismissNotification(notificationId)
          }}
          variant='outline'
        >
          {t('generic.close')}
        </Button>
      )
    }

    return (
      <LoadingButton
        isLoading={isDismissing}
        onClick={async () => {
          await handleDismissNotification(notificationId)
        }}
      >
        {t('generic.close')}
      </LoadingButton>
    )
  }

  return (
    <LoadingButton
      isLoading={isDismissing}
      onClick={async () => {
        if (action.dismissOnClick) {
          await handleDismissNotification(notificationId)
        }

        // Short circuit if there is no url and we want to dismiss on click: we just want to close the notification
        if (!action.url) {
          return
        }

        if (startsWith(action.url, 'http')) {
          window.location.href = action.url
          return
        }

        navigate(action.url)
      }}
    >
      {t(action.label)}
    </LoadingButton>
  )
}

/**
 * This hook is used to create notifications for the authenticated user on app load based on profile conditions.
 * It is called from src/Layout.tsx
 * We can later fetch data from the API and create notifications based on that data.
 */
const useAuthenticatedUserNotifications = () => {
  const { authenticated, profile } = useCurrentUserContext()
  const { notifications, createNotification, dismissNotification } = useNotifications()
  const [notificationCreated, setNotificationCreated] = useState(false)
  const { api } = useAppContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useQuery({
    enabled: authenticated,
    queryKey: ['notifications'],
    queryFn: async () => {
      const data = await api.getNotifications()
      return data?.data ?? []
    },
    onSuccess (apiNotifications) {
      if (apiNotifications?.length > 0) {
        apiNotifications.forEach((notification: ApiNotification) => {
          // Prevent duplicate notifications
          if (notifications.find(n => n.externalId === notification.id)) {
            return
          }

          const verifyEmailNotification = notification.actions?.[0].url?.includes('verify-email')

          createNotification({
            context: notification.context as NotificationContextType,
            message: notification.message,
            label: notification.label,
            externalId: notification.id,
            actions: notification.actions?.length
              ? () => (
                <>
                  {verifyEmailNotification && <ActionButton notificationId={notification.id} addCloseButton />}
                  {notification?.actions?.map(action => (
                    <ActionButton
                      notificationId={notification.id}
                      action={action}
                      key={action.url}
                    />
                  ))}
                </>
              )
              : () => (
                <ActionButton notificationId={notification.id} />
              )
          })
        })
      }
    }
  })

  useEffect(() => {
    setNotificationCreated(false)
  }, [t])

  useEffect(() => {
    if (authenticated && profile?.userId && !notificationCreated) {
      dismissNotification({ externalId: 'strong-auth' })

      if (!profile?.isStronglyAuthenticated) {
        createNotification({
          context: 'global',
          message: 'alert.strongAuthNeeded',
          label: 'alert.label.actionNeeded',
          externalId: 'strong-auth',
          actions: () => (
            <Button onClick={() => {
              navigate('/strong-auth')
            }}
            >
              {t('alert.verifyWithNets')}
            </Button>
          )
        })
      }

      setNotificationCreated(true)
    }
  }, [authenticated, createNotification, setNotificationCreated, navigate, profile, t, notificationCreated, dismissNotification])
}

export default useAuthenticatedUserNotifications
