import { cn } from 'lib/utils'

export function TypographyP ({
  children,
  className,
  ...props
}: {
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={cn('leading-6 [&:not(:first-child)]:mt-4', className)}
      {...props}
    >
      {children}
    </p>
  )
}
export function TypographyLead ({
  children,
  className,
  ...props
}: {
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={cn('text-md', className)} {...props}>
      {children}
    </p>
  )
}

export function TypographyMuted ({
  children,
  className,
  ...props
}: { children?: React.ReactNode } & React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={cn('text-sm text-muted-foreground', className)} {...props}>
      {children}
    </p>
  )
}
