import { useAppContext } from 'contexts/AppContext'
import { Navigate } from 'react-router-dom'
import LazyLoading from '../components/LazyLoading'

export const Callback = () => {
  const { redirectTarget, appLoaded } = useAppContext()
  return (
    appLoaded
      ? <Navigate replace to={redirectTarget ?? '/'} />
      : <LazyLoading />
  )
}
