import { cn } from 'lib/utils'
import React from 'react'

export function Content ({
  className,
  children,
  ...restProps
}: {
  className?: string
  children?: React.ReactNode
}) {
  return (
    <div {...restProps} className={cn('container my-8', className)}>
      {children}
    </div>
  )
}
