import { cn } from 'lib/utils'

function Section ({ children, className, fullWidth = false }: { children?: React.ReactNode, className?: string, fullWidth?: boolean }) {
  return (
    <section className={cn('my-8 md:my-10 container', fullWidth ? 'w-full max-w-full' : '', className)}>
      {children}
    </section>
  )
}

export { Section }
