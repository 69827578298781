import React from 'react'
import { Heading } from './ui/heading'
import { TypographyLead } from './ui/typography'

export default function Header ({ title, subtitle, subtitles }: { title?: React.ReactNode, subtitle?: React.ReactNode, subtitles?: string[] }) {
  return (
    <div className='mb-4 flex flex-col gap-4'>
      {title && (
        <Heading size='xl' as='h1' className='lg:w-2/3'>
          {title}
        </Heading>
      )}

      {subtitle && (
        <TypographyLead className='lg:w-2/3'>
          {subtitle}
        </TypographyLead>
      )}

      {subtitles && subtitles.length >= 0 && (
        <>
          {subtitles.map((text, index) => (
            <React.Fragment key={index}>
              {text && (
                <TypographyLead className='lg:w-2/3'>
                  {text}
                </TypographyLead>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  )
}
