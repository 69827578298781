import { useLocation } from 'react-router'
import { parseJwt } from 'utils/helpers'

type TokenData = Record<string, string>

// TODO: should be moved to somewhere more common
export default function useRegisterFlow () {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const sessionToken = query.get('session_token')
  const state = query.get('state')
  const tokenData: TokenData = parseJwt(sessionToken)

  return {
    tokenData,
    state,
    sessionToken
  }
}
