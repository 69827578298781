import { cn } from 'lib/utils'
import { Loader2 } from 'lucide-react'

interface LazyLoadingProps {
  fullScreen?: boolean
  small?: boolean
  className?: string
}

function LazyLoading ({ fullScreen = false, small = false, className }: LazyLoadingProps): JSX.Element {
  return (
    <div
      className={cn(
        'flex flex-col justify-center items-center mt-8 text-lg',
        fullScreen ? 'h-[100vh]' : '',
        className
      )}
    >
      <div>
        <Loader2
          className={cn(
            'animate-spin text-gray-300',
            small ? 'h-8 w-8' : 'h-16 w-16'
          )}
        />
      </div>
    </div>
  )
}

export default LazyLoading
