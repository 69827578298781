import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from 'components/ui/alert-dialog'

import { useAppContext } from '../../contexts/AppContext'
import { useMutation } from '@tanstack/react-query'
import LoadingButton from 'components/LoadingButton'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button } from 'components/ui/button'

export const LogoutMenu = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { api } = useAppContext()

  const mutationFn = async () => {
    setLoading(true)
    await api.logout()
  }

  const { mutate } = useMutation({
    mutationFn
  })

  const handleClick = () => { mutate() }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button>
          {t('generic.logout')}
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('generic.areYouSure')}
          </AlertDialogTitle>

          <AlertDialogDescription>
            {t('loginMenu.logoutDescription')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('generic.cancel')}
          </AlertDialogCancel>

          <LoadingButton onClick={handleClick} isLoading={loading}>
            {t('generic.continue')}
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
