import NaviBar from './nav/NaviBar'
import Footer from './Footer'
import { Outlet, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NotificationProvider } from 'contexts/NotificationContext'
import { DataProvider } from 'contexts/DataContext'
import { CurrentUserProvider, useCurrentUserContext } from 'contexts/CurrentUserContext'
import { AppContextProvider } from 'contexts/AppContext'
import useAuthenticatedUserNotifications from 'hooks/useAuthenticatedUserNotifications'
import ScrollToTop from 'components/ScrollToTop'
import LazyLoading from 'components/LazyLoading'
import { Section } from 'components/ui/section'
import { Content } from 'components/ui/content'

function Layout () {
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const currentLanguage = i18n.language
  const newLanguage = searchParams.get('lang')

  if (newLanguage && currentLanguage !== newLanguage) {
    void i18n.changeLanguage(newLanguage)
  }

  return (
    <AppContextProvider>
      <CurrentUserProvider>
        <DataProvider>
          <NotificationProvider>
            <ScrollToTop />
            <NaviBar />
            <MainContent />
            <Footer />
          </NotificationProvider>
        </DataProvider>
      </CurrentUserProvider>
    </AppContextProvider>
  )
}

const MainContent = () => {
  const { profileLoading, profileLoadingError } = useCurrentUserContext()
  const { t } = useTranslation()
  useAuthenticatedUserNotifications()

  return (
    <main className='top-container flex-1'>
      {!profileLoading
        ? (
          <>
            {/* If we are authenticated but profile loading has failed, show an error */}
            {profileLoadingError
              ? (
                <Section>
                  <div>
                    {t('generic.profileLoadingFailed')}
                  </div>
                </Section>
              )
              : (
                <main className='flex-1'>
                  <Outlet />
                </main>
              )}
          </>
        )
        : (
          <Content>
            <LazyLoading />
          </Content>
        )}
    </main>

  )
}

export default Layout
