import {
  Auth0Client,
  type Auth0ClientOptions,
  type LogoutOptions,
  type User,
  type AuthorizationParams,
  type PopupLoginOptions
} from '@auth0/auth0-spa-js'

import type { AppConfigurationType } from '../contexts/AppContext'
import { type HideProfile, inflateGenericUser, transformHideProfileToExternalProfile } from './HideProfiler'
import { type CourseRecord } from 'pages/learningDataSharingAgreements/reports/columns'

import PromisePool from '@supercharge/promise-pool'

import type {
  SupervisorInvitationDetails,
  LearningDataSharingAgreementInfo,
  ProvisioningAgreementInfo,
  CourseInvitationDetails,
  ReplyConsentsPersonalInformation,
  CredentialInfo,
  CourseInvitationState
} from '../../../aee_types/src/types'

import { type DatabasePAProductProgramInfo, type DatabaseStudyRecordInfo } from '../../../aee_types/src/databaseTypes'

export class ApiError extends Error {
  httpStatusCode?: number
  session?: string
  errorCode?: string
  data?: any

  constructor (message: string) {
    super(message)
    this.name = 'ApiError'
  }

  withHttpStatusCode (statusCode: number) {
    this.httpStatusCode = statusCode
    return this
  }

  withErrorCode (errorCode: string) {
    this.errorCode = errorCode
    return this
  }

  withData (data: any) {
    this.data = data
    return this
  }

  withSession (session: any) {
    this.session = session
    return this
  }
}

export class ApiResponse<T> {
  statusCode?: number
  data?: T

  withData (data: any) {
    this.data = data
    return this
  }
}

export class LoginResult {
  token: string = ''
  profile: User = {}
}

class IamApi {
  private _apiBaseUrl: string = ''
  private _apiKey: string = ''
  private _iamClient?: Auth0Client = undefined
  private _iamClientId: string = ''
  private _iamDomain: string = ''
  private _logoutUrl: string | undefined = undefined
  private _loginUrl: string | undefined = undefined
  private readonly _strongAuthProviderName = 'NETS' // TODO: Move to settings.

  async basicFetch (
    // TODO: Why is token a required parameter when it's explicitly set to an empty string in cases where no token is needed? (e.g. finalizeVerifyCustomEmail and logDiagnostics)
    token: string,
    apiKey: string,
    verb: string,
    url: string,
    body: any = undefined
  ) {
    const headers = {
      Authorization: `Bearer ${token}`,
      ...(apiKey && { 'x-api-key': apiKey }),
      'Content-Type': 'application/json;charset=UTF-8'
    }

    const options: any = {
      method: verb,
      headers
    }

    const callShouldIncludeRequestbody = body && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(verb)

    if (callShouldIncludeRequestbody) {
      options.body = JSON.stringify(body || {})
    }

    const iam = await this.getIamClient()
    const isAuthenticated = iam instanceof Auth0Client ? iam.isAuthenticated() : false

    return await fetch(url, options)
      .then(async (response) => {
        // TODO: Implement something more elegant than this.
        if (response.status === 401) {
          const params = new URLSearchParams(window.location.search)
          const sessionToken = params.get('session_token')
          // If we have a session token, we are in the middle of a login flow but the token is no more valid
          // Let's redirect to the root to start a new login flow
          if (sessionToken) {
            window.location.href = '/'
          // If we are not authenticated, we should refresh the page to update the token and try again
          // If after reload we are still not authenticated, we will be shown the login page
          } else if (!isAuthenticated) {
            window.location.reload()
          // If we are authenticated, we should log out since the token is no longer valid and just causes errors
          } else {
            await this.logout()
          }
        }

        let body
        const text = await response.text()

        try {
          body = JSON.parse(text)
        } catch (e) {
          body = text
        }

        if (response.ok) {
          return body
        }

        const error = new ApiError(typeof body === 'string' ? body : body.message)

        error.withHttpStatusCode(response.status)
        error.withSession(body.session)

        if (typeof body !== 'string') {
          error.withErrorCode(body.code)
          error.withData(body.data)
        }

        throw error
      })
  }

  async initApi (config: AppConfigurationType) {
    const options: Auth0ClientOptions = {
      domain: config.iam_domain,
      clientId: config.iam_client_id,
      // This is not that safe (see XSS attacks), but allows to migitate strict browsers 3rd party cookie behaviour
      cacheLocation: config.cacheLocation ?? 'localstorage',
      authorizationParams: {
        redirect_uri: config.iam_redirect_uri,
        // This is critical. Not having that turns Token into OpaqueToken, not JWTToken
        audience: config.APIGATEWAY_AUDIENCE
      }
    }

    this._iamClient = new Auth0Client(options)
    this._iamClientId = config.iam_client_id
    this._iamDomain = config.iam_domain
    this._loginUrl = config.iam_redirect_uri
    this._logoutUrl = config.iam_redirect_uri
    this._apiBaseUrl = `${config.APIGATEWAY_DOMAIN}${config.APIGATEWAY_BASE_PATH}`
    this._apiKey = config.APIGATEWAY_XAPIKEY
  }

  private async getIamClient (): Promise<Auth0Client | ApiResponse<any>> {
    if (this._iamClient) {
      return this._iamClient
    }

    throw new Error('API not yet initialized')
  }

  async handleRedirectCallback () {
    const iam = await this.getIamClient()

    if (iam instanceof Auth0Client) {
      return await iam.handleRedirectCallback()
    }

    return iam
  }

  async getCurrentToken () {
    const iam = await this.getIamClient()

    if (iam instanceof Auth0Client) {
      return await iam.getTokenSilently()
    }
  }

  async getIdTokenUser () {
    const user = await this._iamClient?.getUser()
    if (user) {
      return inflateGenericUser(user)
    }
  }

  async getCurrentUser () {
    const idToken = await this._iamClient?.getUser()
    const token = await this.getCurrentToken()
    const currentUserId = idToken?.sub

    if (!currentUserId || !token) {
      return undefined
    }
    return await this.getUserById(token, currentUserId)
  }

  async signUpUniversal (lang = 'en', parameters: AuthorizationParams = {}, redirectTarget = '/register/success') {
    const signUpParameters = {
      screen_hint: 'signup',
      ...parameters
    }

    await this._loginUniversal(lang, signUpParameters, redirectTarget)
  }

  async loginUniversal (lang = 'en', parameters: AuthorizationParams = {}, redirectTarget = '/') {
    await this._loginUniversal(lang, parameters, redirectTarget)
  }

  async _loginUniversal (lang = 'en', parameters: AuthorizationParams = {}, redirectTarget = '/') {
    const iam = await this.getIamClient()

    if (iam instanceof Auth0Client) {
      await iam.loginWithRedirect({
        authorizationParams: {
          ui_locales: lang,
          redirect_uri: `${this._loginUrl ?? ''}/callback`,
          prompt: 'login',
          ...parameters
        },
        appState: {
          redirectTarget
        }
      })
    }
  }

  async loginWithStrongAuth (auth0userId: string | undefined = undefined, lang: string = 'en') {
    const response = new ApiResponse<any>()
    // Generate "initiator" value, that will allow to make sure that this Strong Authentication result (Temp User) will be mergeable only to this Auth0 account.
    const initiator = auth0userId?.replace('auth0|', '')
    // See https://simplelocalize.io/data/locales/ for locales namings
    const languages: any = {
      en: 'en-GB',
      fi: 'fi-FI',
      sv: 'sv-SE'
    }
    const languageCode = languages[lang] ?? languages.en
    const loginOptions: PopupLoginOptions = {
      authorizationParams: {
        connection: this._strongAuthProviderName,
        ui_locales: languageCode,
        login_hint: initiator
        // acr_values: 'fi_tupas'
      }
    }

    const threeMinutes = 3 * 60

    const configOptions = {
      timeoutInSeconds: threeMinutes
    }

    const iam = await this.getIamClient()
    if (iam instanceof Auth0Client) {
      // Popup source code "loginWithPopup": https://github.com/auth0/auth0-spa-js/blob/2ac288b97ad484efd53f7092e3a01de552c8fe64/src/Auth0Client.ts#L349
      // And "runPopup": https://github.com/auth0/auth0-spa-js/blob/2ac288b97ad484efd53f7092e3a01de552c8fe64/src/utils.ts#L99
      await iam.loginWithPopup(loginOptions, configOptions)
      response.withData(await iam.getUser())
    }

    return response
  }

  async deleteUser (token: string, userId: string) {
    const result = new ApiResponse<HideProfile>()

    await this.basicFetch(
      token,
      this._apiKey,
      'DELETE',
      `${this._apiBaseUrl}/users/${userId}`
    )

    // Logout user as he is not a valid user anymore, and will not see any real data, only error pages.
    await this.logout()
    return result
  }

  // TODO: Check if it does not really require token (memeApi impl), and why.
  async forgotPasswordAction (token: string, email: string) {
    const result = new ApiResponse()

    const reset = await this.basicFetch(
      token,
      '',
      'POST',
      `${this._apiBaseUrl}/change-password`,
      {
        email
      }
    )

    result.withData(reset)
    return result
  }

  async getUserById (
    token: string,
    id: string
  ): Promise<ApiResponse<HideProfile>> {
    const response = new ApiResponse<HideProfile>()
    // Asyncrhronously pull basic profile data
    const baseDetailLeafs: any[] = ['', '/roles', '/organizations']
    const { results } = await PromisePool
      .for(baseDetailLeafs)
      .useCorrespondingResults()
      .withConcurrency(3)
      .process(async (urlLeaf, index) => {
        return await this.basicFetch(
          token,
          this._apiKey,
          'GET',
          `${this._apiBaseUrl}/users/${id}${urlLeaf}`
        )
      })
    const fetchUser = results[0]
    const fetchRoles = results[1]
    const fetchCompanies = results[2]
    // Asynchronously pull all roles in all of the User's companies
    const rolesPromise = PromisePool
      .for(fetchCompanies)
      .process(async (company: any, index, pool) => {
        const companyRoles = await this.basicFetch(
          token,
          this._apiKey,
          'GET',
          `${this._apiBaseUrl}/organizations/${company.id}/members/${id}/roles`
        )
        fetchCompanies[index].roles = companyRoles
      })
    // And in parallel with Roles - pull additional information on the Profile
    const detailsLeafs: any[] = ['courses', 'records', 'ldsas/consents/self-service']
    const leafsPromise = PromisePool
      .for(detailsLeafs)
      .useCorrespondingResults()
      .withConcurrency(3)
      .process(async (urlLeaf, index) => {
        return await this.basicFetch(
          token,
          this._apiKey,
          'GET',
          `${this._apiBaseUrl}/${urlLeaf}`
        )
      })
    // When all requests are finished - compose a Profile
    await Promise.allSettled([leafsPromise, rolesPromise]).then(settledResults => {
      const rolesArray = fetchRoles as Array<{ id: string, name: string, description: string }>
      const roleNames = rolesArray.map((role) => role.name)
      const detailLeafResult: any[] = (settledResults[0] as any).value.results
      const credentials = detailLeafResult[0]
      const studyRecords = detailLeafResult[1]
      const consents = detailLeafResult[2]
      const userProfile = inflateGenericUser(
        fetchUser,
        roleNames,
        fetchCompanies,
        credentials,
        studyRecords,
        consents
      )
      response.withData(userProfile)
    })
    return response
  }

  async updateUserById (token: string, id: string, data: Partial<HideProfile>) {
    const externalUserData = transformHideProfileToExternalProfile(data)
    const response = new ApiResponse<HideProfile>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'PATCH',
      `${this._apiBaseUrl}/users/${id}`,
      externalUserData
    )

    // Please note! This request returns userinfo, but without any additional info (roles, blocked, consents,etc)
    response.withData(inflateGenericUser(result))
    return response
  }

  async addSecondaryEmailToUser (token: string, user: any, email: string) {
    let result = new ApiResponse<HideProfile>()

    result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/secondary-email/${encodeURIComponent(email)}`
    )
    await this.sendVerifyCustomEmail(token, user.userId, email)
    return result
  }

  async sendVerifyCustomEmail (token: string, userId: string, email?: string) {
    // Token here is either proper User Token, or AccessToken from registration process.
    await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/verify-email`,
      {
        user_id: userId,
        email: encodeURIComponent(email ?? '')
      }
    )
  }

  async emailPreRegister (token: string, email?: string) {
    await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/email-pre-register`,
      {
        email: encodeURIComponent(email ?? '')
      }
    )
  }

  async finalizeVerifyCustomEmail (token: string, userId: string, verifyCode: string, email: string) {
    // Token here is either proper User Token, or AccessToken from registration process.
    await this.basicFetch(
      token,
      this._apiKey,
      'PATCH',
      `${this._apiBaseUrl}/verify-email`,
      {
        user_id: userId,
        email: encodeURIComponent(email ?? ''),
        verify_code: verifyCode
      }
    )

    const result = new ApiResponse<HideProfile>()
    return result
  }

  async changeMainEmail (token: string, userId: string, newMainEmail: string) {
    await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/change-email`,
      {
        user_id: userId,
        email: encodeURIComponent(newMainEmail ?? '')
      }
    )

    const result = new ApiResponse<HideProfile>()
    return result
  }

  async removeEmailFromUser (token: string, email: string) {
    const response = new ApiResponse()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'DELETE',
      `${this._apiBaseUrl}/secondary-email/${encodeURIComponent(email)}`
    )
    return response.withData(result)
  }

  async getUserCourses (token: string): Promise<ApiResponse<CredentialInfo[]>> {
    const response = new ApiResponse<CredentialInfo[]>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/courses`
    )

    return response.withData(result)
  }

  async getUserStudyRecords (token: string): Promise<ApiResponse<DatabaseStudyRecordInfo[]>> {
    const response = new ApiResponse<DatabaseStudyRecordInfo[]>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/records`
    )

    return response.withData(result)
  }

  async getUserDataSharingAgreementConsents (token: string): Promise<ApiResponse<ReplyConsentsPersonalInformation[]>> {
    const response = new ApiResponse<any[]>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/ldsas/consents/self-service`
    )

    return response.withData(result)
  }

  // TODO: This is basically the same as "setLdsaConsent", but with different parameters. Can we use the same in the user consents page and ldsa invitation flow???
  async setAgreementConsentState (token: string, consentId: string, status: boolean): Promise<ApiResponse<any>> {
    const response = new ApiResponse<any>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/ldsas/consents/self-service`,
      {
        consentId,
        consentedToLdsa: status
      }
    )

    return response.withData(result)
  }

  async getUserLdsas (token: string): Promise<ApiResponse<LearningDataSharingAgreementInfo[]>> {
    const response = new ApiResponse<LearningDataSharingAgreementInfo[]>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/ldsas`
    )

    return response.withData(result)
  }

  async getLdsaStudyRecords (token: string): Promise<ApiResponse<CourseRecord[]>> {
    const response = new ApiResponse<CourseRecord[]>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/ldsas/study-records`
    )

    return response.withData(result)
  }

  async getSupervisorInvitationTokenDetails (token: string, invitationToken?: string) {
    const response = new ApiResponse<SupervisorInvitationDetails>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/supervisor-invitations/${invitationToken ?? ''}`
    )

    return response.withData(result)
  }

  async handleSupervisorInvitationToken (token: string, invitationToken?: string) {
    const response = new ApiResponse<HideProfile>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/supervisor-invitations/`,
      {
        b2bUserToken: invitationToken
      }
    )

    return response.withData(result)
  }

  async getCourseInvitation (invitationCode: string): Promise<ApiResponse<CourseInvitation>> {
    const response = new ApiResponse<CourseInvitation>()

    const data = await this.basicFetch(
      '',
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/course-invitations/${invitationCode}`
    )

    if (!data) {
      throw new Error('No invitation data found')
    }

    const invitationDetails = data as CourseInvitationDetails

    const result: CourseInvitation =
    {
      invitationCode: invitationDetails.invitation_code,
      courseName: invitationDetails.invitationSubject ?? invitationDetails.product_name,
      agreementName: invitationDetails.agreementName,
      hasLdsa: invitationDetails.has_ldsa,
      ldsaSummary: invitationDetails.ldsa_summary,
      ldsaDescription: invitationDetails.ldsa_description,
      isDataSharingConsentRequired: invitationDetails.requires_consent ?? false,
      selectedProgram: invitationDetails.selected_program,
      availablePrograms: invitationDetails.available_programs,
      consentScope: invitationDetails.consentScope,
      program_preselected: invitationDetails.program_preselected
    }

    return response.withData(result)
  }

  async setCourseInvitation (token: string, courseInvitationState: CourseInvitationState): Promise<ApiResponse<HideProfile>> {
    const response = new ApiResponse<HideProfile>()
    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/course-invitations/${courseInvitationState.invitationCode}`,
      courseInvitationState
    )

    return response.withData(result)
  }

  async anonymouslyRejectCourseInvitation (courseInvitationState: CourseInvitationState): Promise<ApiResponse<HideProfile>> {
    // All anonymous endpoints have their own auth guards. We can not have anonymous and authenticated at same endpoint, thus the division.
    const response = new ApiResponse<HideProfile>()

    const result = await this.basicFetch(
      '',
      this._apiKey,
      'DELETE',
      `${this._apiBaseUrl}/course-invitations/${courseInvitationState.invitationCode}`,
      courseInvitationState
    )

    return response.withData(result)
  }

  async getLdsaInvitationDetails (token: string, ldsaInvitationCode: string): Promise<ApiResponse<ParticipantDataSharingAgreement>> {
    const response = new ApiResponse<ParticipantDataSharingAgreement>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/ldsa-invitations/${ldsaInvitationCode}`
    )

    return response.withData(result)
  }

  async consumeLdsaInvitation (token: string, consents: Array<Partial<DataSharingAgreementConsentItem>>, agreementCode: string, invitationCode: string): Promise<ApiResponse<HideProfile>> {
    const response = new ApiResponse<HideProfile>()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/ldsa-invitations/${invitationCode}`,
      {
        consents,
        agreementCode
      }
    )
    return response.withData(result)
  }

  async getPAInvitations (token: string, paId: string): Promise<ApiResponse<ProvisioningAgreementParticipants[]>> {
    const response = new ApiResponse<any>()

    const data = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/provisioning-agreements/${paId}/invitations`
    )
    return response.withData(data)
  }

  async getLdsaParticipants (token: string, ldsaId: string): Promise<ApiResponse<{ invited: LdsaInvitee[], consents: LdsaParticipantConsent[] }>> {
    const response = new ApiResponse<any>()

    const data = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/ldsas/${ldsaId}/invitations`
    )
    return response.withData(data)
  }

  async logout (logoutOptionsParam?: LogoutOptions) {
    let logoutOptions: LogoutOptions = {}

    if (this._logoutUrl) {
      logoutOptions = {
        ...logoutOptionsParam,
        logoutParams: {
          returnTo: this._logoutUrl,
          ...logoutOptionsParam?.logoutParams
        }
      }
    }

    const iam = await this.getIamClient()

    if (iam instanceof Auth0Client) {
      await iam.logout(logoutOptions)
    } else {
      return iam
    }
  }

  async strongAuthEnrich (token: string, userId: string, secondaryUserId: string) {
    await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/strong-auth-enrich`,
      {
        user_id: userId,
        sa_user_id: secondaryUserId
      }
    )
  }

  async isAuthenticated () {
    const iam = await this.getIamClient()
    return iam instanceof Auth0Client ? await iam.isAuthenticated() : false
  }

  async sendLdsaInvitation (token: string, agreementNumber: string, data: { emails: string[], invitationLanguage: string }): Promise<ApiResponse<any>> {
    const response = new ApiResponse()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/ldsas/company-service/invite-emails/${agreementNumber}`,
      data
    )

    return response.withData(result)
  }

  async resendLdsaInvitations (token: string, agreementNumber: string, invitationCode: string): Promise<ApiResponse<any>> {
    const response = new ApiResponse()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/ldsas/company-service/resend-invite-emails/${agreementNumber}`,
      {
        invitationCode
      }
    )
    return response.withData(result)
  }

  async removeUserFromLdsa (token: string, agreementCode: string, userId?: string, invitationId?: string): Promise<ApiResponse<any>> {
    const response = new ApiResponse()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'DELETE',
      `${this._apiBaseUrl}/ldsas/company-service/remove-participant/${agreementCode}`,
      {
        userId,
        invitationId
      }
    )

    return response.withData(result)
  }

  async addAgreementToUser (token: string, agreementCode: string) {
    const response = new ApiResponse<any>()

    await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/ldsas/self-service/${agreementCode}`
    )

    return response.withData({ agreementCode })
  }

  async logDiagnostics (message: string, level: 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL' = 'INFO', data?: any) {
    await this.basicFetch(
      '',
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/log-diagnostics`,
      {
        message,
        level,
        data
      }
    )
  }

  async getNotifications (): Promise<ApiResponse<ApiNotification[]>> {
    const response = new ApiResponse<any>()
    const token = await this.getCurrentToken()

    if (!token || typeof token !== 'string') {
      throw new Error('No token found')
    }

    const result: ApiNotification[] = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/notifications`
    )

    return response.withData(result)
  }

  async dismissNotification (notificationId: string): Promise<ApiResponse<any>> {
    const response = new ApiResponse<any>()
    const token = await this.getCurrentToken()

    if (!token || typeof token !== 'string') {
      throw new Error('No token found')
    }

    const result: ApiNotification[] = await this.basicFetch(
      token,
      this._apiKey,
      'PUT',
      `${this._apiBaseUrl}/notifications`,
      {
        ids: [notificationId]
      }
    )

    return response.withData(result)
  }

  async getProvisioningAgreements (): Promise<ApiResponse<ProvisioningAgreementInfo[]>> {
    const response = new ApiResponse<ProvisioningAgreementInfo[]>()
    const token = await this.getCurrentToken()

    if (!token || typeof token !== 'string') {
      throw new Error('No token found')
    }

    const result: ProvisioningAgreementInfo[] = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/provisioning-agreements`
    )

    return response.withData(result)
  }

  async getProvisioningAgreementProductPrograms (productRowId: string): Promise<ApiResponse<DatabasePAProductProgramInfo[]>> {
    const response = new ApiResponse<DatabasePAProductProgramInfo[]>()
    const token = await this.getCurrentToken()

    if (!token || typeof token !== 'string') {
      throw new Error('No token found')
    }

    const result: DatabasePAProductProgramInfo[] = await this.basicFetch(
      token,
      this._apiKey,
      'GET',
      `${this._apiBaseUrl}/provisioning-agreements/programs/${productRowId}`
    )
    return response.withData(result)
  }

  async removePAInvitation (token: string, invitationCode: string) {
    const response = new ApiResponse()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'DELETE',
      `${this._apiBaseUrl}/provisioning-agreements/company-service/remove-invitation/${invitationCode}`
    )
    return response.withData(result)
  }

  async sendProvisioningAgreementInvitation (token: string, agreementNumber: string, data: ProvisioningAgreementInvitation): Promise<ApiResponse<any>> {
    const response = new ApiResponse()

    const result = await this.basicFetch(
      token,
      this._apiKey,
      'POST',
      `${this._apiBaseUrl}/provisioning-agreements/company-service/invite-emails/${agreementNumber}`,
      data
    )

    return response.withData(result)
  }
}

export interface CourseInvitation {
  invitationCode: string
  courseName?: string
  agreementName?: string
  hasLdsa?: boolean
  ldsaSummary?: string
  ldsaDescription?: string
  consentScope: number
  isDataSharingConsentRequired: boolean
  selectedProgram?: { program_id: string, program_name: string, start_date: string }
  availablePrograms?: Array<{ program_id: string, program_name: string, start_date: string }>
  invitedByEmail?: string
  program_preselected: boolean
}

export interface ParticipantDataSharingAgreement extends LearningDataSharingAgreementInfo {
  consents?: DataSharingAgreementConsentItem[]
}

export interface DataSharingAgreementProduct {
  id: string
  productName: string
  productRowId: string
  consentScope?: string
}

export interface DataSharingAgreementConsentItem {
  consentId: string
  productRowId: string
  productName?: string
  status?: boolean
  consentScope?: string
}

export interface LdsaInvitee {
  inviteEmail: string
  status: 'invited'
}

export interface LdsaParticipantConsent {
  inviteEmail: string
  status: 'active' | 'revoked' | 'none'
  consentDate: string
  productName: string
  participantName: string
  participantEmail: string
  dateOfBirth: string
  participantId: string
  lastModified: string
}

export type LdsaParticipant = LdsaInvitee | LdsaParticipantConsent

export interface ApiNotificationAction {
  label: string
  url?: string
  buttonVariant?: string
  dismissOnClick?: boolean
}

export interface ApiNotification {
  id: string
  message: string
  label: string
  context: string
  actions?: ApiNotificationAction[]
}

export interface ProvisioningAgreementParticipants {
  dateOfBirth: any
  participantId: string
  inviteEmail: string
  productName: string
  usedQuota: number
  quotaType: string
  quotaUnit: string
  invitationSentByEmail: string
  usedByEmail: string
  status: string
  invitationCode: string
  lastModified: string
}

export interface ProvisioningAgreementInvitation {
  emails: string[]
  timetableSelection: string
  productId: string
  programId: string
  invitationLanguage: string
}

export { IamApi }
