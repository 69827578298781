import useUrlState from '@ahooksjs/use-url-state'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { Fragment } from 'react'
import { supportedLanguages, languageLabels } from 'i18n'

const languages = supportedLanguages.map((language: string) => ({
  label: languageLabels[language],
  value: language
}))

function LanguageSelector () {
  const [_, setUrlState] = useUrlState() // eslint-disable-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation()
  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng).catch(console.error)
    setUrlState({ lang: lng })
  }
  const currentLanguage = languages.find(lang => lang.value === i18n.language) ?? languages[1]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='outline' className='sm:mr-0'>{currentLanguage.label}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='center' className='w-60'>
        <DropdownMenuLabel>{t('language.select')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup value={currentLanguage.value} onValueChange={handleLanguageChange}>
          {languages.map((lang, index) => (
            <Fragment key={lang.value}>
              {index !== 0 && <DropdownMenuSeparator />}
              <DropdownMenuRadioItem value={lang.value}>{lang.label}</DropdownMenuRadioItem>
            </Fragment>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export { LanguageSelector }
