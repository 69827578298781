import { createContext, useState, useContext, useRef } from 'react'
import { IamApi } from '../ext/IamApi'
import { type CacheLocation, type RedirectLoginResult } from '@auth0/auth0-spa-js'
import { useEffectOnce } from 'usehooks-ts'
import { useSearchParams } from 'react-router-dom'

const CONFIG = {
  iam_domain: process.env.REACT_APP_iam_domain ?? '',
  iam_client_id: process.env.REACT_APP_iam_client_id ?? '',
  iam_redirect_uri: process.env.REACT_APP_iam_redirect_uri ?? '',
  APIGATEWAY_DOMAIN: process.env.REACT_APP_APIGATEWAY_DOMAIN ?? '',
  APIGATEWAY_XAPIKEY: process.env.REACT_APP_APIGATEWAY_XAPIKEY ?? '',
  APIGATEWAY_BASE_PATH: '/v1',
  APIGATEWAY_AUDIENCE: 'DelegatedAdminAPI'
}

export interface AppConfigurationType {
  iam_domain: string
  iam_client_id: string
  iam_redirect_uri: string
  APIGATEWAY_DOMAIN: string
  APIGATEWAY_XAPIKEY: string
  APIGATEWAY_BASE_PATH: string
  APIGATEWAY_AUDIENCE: string
  cacheLocation?: CacheLocation
}

interface AppContextType {
  api: IamApi
  appLoaded: boolean
  redirectTarget: string | null
}

const AppContextEmptyValue = {
  api: new IamApi(),
  appLoaded: false,
  redirectTarget: null
}

export const AppContext = createContext<AppContextType>(AppContextEmptyValue)

export const AppContextProvider = (props: any) => {
  const [data, setData] = useState<AppContextType>(AppContextEmptyValue)
  const [searchParams, setSearchParams] = useSearchParams()

  // Let's prevent handling data fetching more than once
  // See https://community.auth0.com/t/error-invalid-state-when-calling-handleredirectcallback-on-react-app/95329/3 for more details
  const isRedirectCallbackHandled = useRef(false)

  const fetchData = async () => {
    // Initialize API
    const api = new IamApi()
    await api.initApi(CONFIG)

    // If there are code and state params available, we want to handle the redirect callback
    if (searchParams.get('code') && searchParams.get('state')) {
      // A bit hacky way to prevent redirect callback from firing twice
      if (!isRedirectCallbackHandled.current) {
        isRedirectCallbackHandled.current = true
        let redirectTarget = null

        try {
          const { appState } = await api.handleRedirectCallback().catch(console.error) as RedirectLoginResult<{ redirectTarget?: string }>
          redirectTarget = appState?.redirectTarget ? appState.redirectTarget : null

          searchParams.delete('code')
          searchParams.delete('state')

          setSearchParams(searchParams, { replace: true })
        } finally {
          const updatedData = {
            api,
            redirectTarget
          }
          setData((p) => ({ ...p, ...updatedData, appLoaded: true }))
        }
      }
    } else {
      setData((p) => ({ ...p, api, appLoaded: true }))
    }
  }

  useEffectOnce(() => {
    fetchData().catch(console.error)
  })

  return (
    <AppContext.Provider value={data}>{props.children}</AppContext.Provider>
  )
}

export const useAppContext = () => {
  const {
    api,
    appLoaded,
    redirectTarget
  } = useContext(AppContext)

  return {
    api,
    appLoaded,
    redirectTarget
  }
}
